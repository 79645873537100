import APIclient from "API/Client";
import { GetBaseAuthObject } from "../../utility";
import * as featureFlagsControllers from "Redux/splitio/controllers";

export const FETCH_USER_REQUEST = "FETCH_USER_REQUEST";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_FAILURE = "FETCH_USER_FAILURE";

export const fetchGetDataReq = (data) => {
  return {
    type: FETCH_USER_REQUEST,
    data: data,
  };
};

export const fetchGetDataSuccess = (data) => {
  return {
    type: FETCH_USER_SUCCESS,
    data: {
      payload: data,
    },
  };
};

export const fetchGetDataError = (data) => {
  return {
    type: FETCH_USER_FAILURE,
    data: data,
  };
};

export const UserInfoFunc = () => {
  return (dispatch) => {
    dispatch(fetchGetDataReq());
    APIclient.getData(`/api/v1/user/info/${GetBaseAuthObject().userId}?organisation_id=${GetBaseAuthObject().orgId}`)
      .then((data) => {
        if (data.data.status > 200) {
          dispatch(fetchGetDataError(data.data));
        } else {
          dispatch(fetchGetDataSuccess(data.data.payload));
        }
        return data;
      })
      .then(() => {
        dispatch(featureFlagsControllers.fetchAllTreatments());
      })
      .catch((err) => {
        dispatch(fetchGetDataError(err));
      });
  };
};
